import { Button, Col, Form, Input, message, Row, Select, Spin, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { 
  PopupAddBackOfficeAccount, 
  PopupBackOfficeAccountPassword, 
  PopupEditBackOfficeAccount 
} from 'components/advanced.component';
import { LayoutNav, LayoutTabPermission } from 'components/layout.component';
import { ENABLE } from 'constants/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get, $patch } from 'services';
import { enumToOptions, timeS2L } from 'utils/common';

// 後台帳號

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const { id }: any = useParams();
  const [form] = useForm();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();

  const [searchParams, setSearchParams] = useState<any>({
    roleId: id || '',
    page: 1,
    rows: 10,
  });

  const { data, mutate, isValidating } = $get({
    url: 'admin/permission/users',
    params: searchParams
  })

  // 權限列表
  const { data: permissionList } = $get({
    url: 'admin/permission/user/roles'
  });
  useEffect(() => {
    if (permissionList && id) {
      form.setFieldValue('roleId', Number(id))
    }
  }, [permissionList])

  const search = async (formData: any) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      roleId: formData.roleId,
      account: formData.account,
      isEnabled: formData.isEnabled,
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setSearchParams({
      ...searchParams,
      rows: 10,
      page: 1,
    })
  }

  const handleEdit = (record: any) => {
    setIsOpenEdit(true);
    setCurrentRecord(record);
  }

  const handlePassword = (record: any) => {
    setIsOpenPassword(true);
    setCurrentRecord(record);
  }

  const handleEditState = (item: any) => {
    const id = item.id;
    if (id) {
      $patch({
        url: `admin/permission/users/${id}/status`,
        send: {
          isEnabled: !item.isEnabled,
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          mutate();
        }
      })
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <LayoutTabPermission activeKey="2" />

        <Form form={form} onFinish={search} initialValues={{
          roleId: '',
          isEnabled: ''
        }}>
          <Row className="mt-1" gutter={[0, 12]}>
            {/* 搜尋 */}
            <Col span={24}>
              <Row align="top" gutter={10}>
                <Col>
                  <Form.Item name="roleId" className="w-12">
                    <Select
                      options={[
                        { value: '', label: i18n.t('allPermission') },
                        ...(permissionList?.Data || []).map((item: any) => (
                          { value: item.id, label: item.name }
                        ))
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="account" className="w-12" >
                    <Input placeholder={`${i18n.t('inputData')}${i18n.t('account')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="isEnabled" className="w-12">
                    <Select
                      options={[
                        { value: '', label: i18n.t('allStatus') },
                        ...enumToOptions(ENABLE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button htmlType="submit" type="primary" loading={isValidating}>{i18n.t('search')}</Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button onClick={onClear}>{i18n.t('clear')}</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 新增按鈕 */}
            {$p('permissionUser.addUser') &&
            <Col span={24}>
              <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>
            </Col>}
            {/* 列表 */}
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  scroll={{ x: 1500 }}
                  dataSource={data?.Data.map((item: any) => ({ key: item.id, ...item }))}
                  columns={[
                    {
                      dataIndex: 'roles',
                      title: i18n.t('permissionName'),
                      fixed: 'left',
                      width: 200,
                      render: (val) => val?.[0]?.name
                    },
                    {
                      dataIndex: 'account',
                      title: i18n.t('backOfficeAccount'),
                      fixed: 'left',
                      width: 160
                    },
                    {
                      dataIndex: 'profile',
                      title: i18n.t('description'),
                      width: 200,
                      render: (val) => val?.description || '-'
                    },
                    {
                      dataIndex: 'profile',
                      title: i18n.t('nameTitle'),
                      width: 160,
                      render: (val) => val?.name || '-'
                    },
                    {
                      dataIndex: 'isEnabled',
                      title: i18n.t('status'),
                      align: 'center',
                      width: 100,
                      render: (val, record) =>
                        <Switch checked={val} disabled={!$p('systemAnnouncement.modifySystemAnnouncement')} 
                        onChange={v => handleEditState(record)} />
                    },
                    {
                      dataIndex: 'createDatetime',
                      title: i18n.t('createTime'),
                      width: 180,
                      render: (val) => timeS2L(val),
                    },
                    {
                      dataIndex: 'operateUser',
                      title: i18n.t('updater'),
                      width: 110,
                      render: (val) => val?.account || '-'
                    },
                    {
                      dataIndex: 'updateDatetime',
                      title: i18n.t('updateTime'),
                      width: 180,
                      render: (val) => timeS2L(val)
                    },
                    {
                      title: i18n.t('operation'),
                      width: 150,
                      fixed: 'right',
                      render: (_, record) => (
                        <>
                          {$p('permissionUser.modifyUser') &&
                          <Button className="size-12" type="link"
                            onClick={() => handleEdit(record)}>{i18n.t('edit')}</Button>}
                          {$p('permissionUser.modifyPassword') &&
                          <Button className="size-12" type="link"
                            onClick={() => handlePassword(record)}>{i18n.t('changePassword')}</Button>}
                        </>
                      )
                    },
                  ]}
                  pagination={{
                    showSizeChanger: true,
                    showTotal: (Total) => `${i18n.t('overall')} ${Total} ${i18n.t('item')}`,
                    total: data?.Total as any,
                    pageSize: searchParams.rows
                  }}
                  onChange={p => setSearchParams({ ...searchParams, page: p.current as any, rows: p.pageSize as any })}
                />
              </Spin>
            </Col>
          </Row>
        </Form>

        <PopupAddBackOfficeAccount isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} mutate={mutate} permissionList={permissionList} />
        <PopupEditBackOfficeAccount isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} record={currentRecord} mutate={mutate} />
        <PopupBackOfficeAccountPassword isOpen={isOpenPassword} close={() => setIsOpenPassword(false)} record={currentRecord} mutate={mutate} />

      </Content>
    </div>
  );
};

export default PageMain;