import { InfoCircleFilled } from "@ant-design/icons"
import { Button, Col, Form, Row, Tooltip } from "antd"
import { useForm } from "antd/es/form/Form"
import { Select } from "antd/lib"
import { Content } from "antd/lib/layout/layout"
import { DatePickerUnix, Export, LayoutNav } from "components/layout.component"
import { ViewSettlementList } from "components/report.component"
import { DATE_TYPE } from "constants/date"
import { ExcelColumns } from "constants/excel"
import dayjs from "dayjs"
import useAccount from "hooks/account.hook"
import useSiteById from "hooks/site-id.hook"
import i18n from "i18n"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { $get } from "services"
import { numColor03, selectFuzzyFilter, specialProviderName, timeL2S, toFormatNumber } from "utils/common"
import { SiteCodeAndName } from "components/site.component";

// 交收報表

const PageMain = () => {
  const { permissions: $p } = useAccount();
  const navigate = useNavigate();
  const [form] = useForm();
  const [platformId, setPlatFormId] = useState<number | null>(null);
  const [reset, setReset] = useState<boolean>(false);
  const [date, setDate] = useState([
    dayjs().add(-1, 'M').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().add(-1, 'M').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.lastMonth
  ]);
  const [params, setParams] = useState<any>({
    startTime: timeL2S(date[0] as string),
    endTime: timeL2S(date[1] as string)
  });

  // 站台資訊
  const { data: $s } = useSiteById(platformId);
  // 遊戲商
  const { data: provider } = $get({ url: `admin/provider-receivable/sites/${platformId}/provider/codes`, allow: !!platformId });
  // 遊戲大類
  const { data: category } = $get({ url: `admin/provider-receivable/sites/${platformId}/categories`, allow: !!platformId });

  const { data, isValidating, mutate } = $get({
    url: `admin/provider-receivable/sites/${platformId}/report`,
    allow: !!platformId,
    params
  });

  const { data: platformInfo } = $get({
    url: `admin/provider-receivable/sites/${platformId}`,
    allow: !!platformId
  });

  const [list, setList] = useState<any>([]);
  useEffect(() => {
    if (data?.Data) {
      const ary: any = [];
      const apiList = JSON.parse(JSON.stringify(data.Data));
      apiList.forEach((record: any) => {
        record.Detail.forEach((item: any, i: number) => {
          ary.push(item);
        });
      });
      setList(ary);
    };
  }, [data, platformId]);

  useEffect(() => {
    if (provider) {
      form.setFieldsValue({
        provider: null,
        category: null,
      });
    };
  }, [provider]);

  const onFinish = ({ platform, provider, category }: {
    platform: number;
    provider: string;
    category: number;
  }) => {
    setPlatFormId(platform);
    setParams({
      ...params,
      providerCode: provider,
      categoryId: category,
      startTime: timeL2S(date[0] as string),
      endTime: timeL2S(date[1] as string)
    });
    mutate();
  };

  const onClear = () => {
    setReset(!reset);
    form.resetFields();
    setPlatFormId(null);
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={{

        }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="top" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="platform" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}${i18n.t('platform')}` }]}>
                    <SiteCodeAndName name="platform" form={form} apiUrl={'admin/provider-receivable/sites' } setSiteId={setPlatFormId} className="w-12"/>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="provider">
                    <Select
                      className="w-12"
                      showSearch
                      filterOption={selectFuzzyFilter}
                      placeholder={i18n.t('gameProvider')}
                      disabled={!form.getFieldValue('platform')}
                      options={
                        provider ? [
                          { value: '', label: i18n.t('ALL') },
                          ...provider?.Data.map((item: any) => ({
                            value: item.providerCode,
                            label: specialProviderName(item.providerName, $s.SiteName, item.providerCode)
                          }))
                        ] : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="category">
                    <Select
                      className="w-12"
                      optionFilterProp="label"
                      placeholder={i18n.t('gameCategory')}
                      disabled={!form.getFieldValue('platform')}
                      options={category?.Data.map((item: any) => ({
                        value: item.Id, label: i18n.t(item.Name)
                      })) || []}
                    />
                  </Form.Item>
                </Col>
                <DatePickerUnix width={200} date={date} setDate={setDate} initDate={reset} format={'YYYY-MM-DD HH:mm:ss'} defaultDateType={DATE_TYPE.lastMonth} ignoreDateType={[DATE_TYPE.lastWeek, DATE_TYPE.thisWeek, DATE_TYPE.today, DATE_TYPE.yesterday]} components={
                  <Col>
                    <Tooltip placement="top" className="size-12" title={i18n.t('settlementTime')}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </Col>
                } />
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 匯出 */}
            {($p('providerReceivable.modifySetting') || $p('siteData.getSiteData')) &&
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  {$p('providerReceivable.modifySetting') && <Col>
                    <Button type="primary" onClick={() => navigate('/report/settlement/setting', { state: { platformId } })}
                      disabled={!platformId}>
                      {i18n.t('setting')}
                    </Button>
                  </Col>}
                  {$p('providerReceivable.exportData') && <Col>
                    <Export 
                      url={`admin/provider-receivable/sites/${platformId}/report/export`}
                      param={params} sheetName={i18n.t('settlementReport')}
                      columns={ExcelColumns.ReportSettlement}
                      externalData={list} otherData={{
                        PlatformName: platformInfo?.Data?.name,
                        PlatformCode: platformInfo?.Data?.code
                      }} disabled={!platformId} />
                  </Col>}
                </Row>
              </Col>}
            {data && 
            <Col span={24}>{`${i18n.t('siteCurrency')}：${data?.Headers.get('Currency')}`}</Col>}
            <Col span={24}>
              <ViewSettlementList loading={isValidating} data={data} reset={reset} columns={[
                {
                  dataIndex: 'ProviderCode',
                  title: i18n.t('gameProvider'),
                  width: 150,
                  render: (val: string) => specialProviderName(val, $s.SiteName, val)
                },
                {
                  dataIndex: 'BetAmount',
                  title: i18n.t('totalBetAmount'),
                  align: 'right',
                  width: 300,
                  render: (val: any) => toFormatNumber(val)
                },
                {
                  dataIndex: 'ValidBetAmount',
                  title: i18n.t('totalValidBets'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => toFormatNumber(val)
                },
                {
                  dataIndex: 'WinLossAmount',
                  title: i18n.t('totalGamePnL'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => <div className={numColor03(val)}>{toFormatNumber(val)}</div>
                },
                {
                  dataIndex: 'ReceivableAmount',
                  title: i18n.t('totalSettlementAmount'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => <div className={numColor03(val)}>{toFormatNumber(val)}</div>
                },
                {
                  dataIndex: 'BonusAmount',
                  title: i18n.t('totalDiscountAmount'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => toFormatNumber(val)
                },
                {
                  dataIndex: 'DonateAmount',
                  title: i18n.t('totalDonateAmount'),
                  align: 'right',
                  width: 150,
                  render: (val: any) => toFormatNumber(val)
                },
              ]} />
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  )
}

export default PageMain