import { Button, Col, Form, Input, Row, Table, message } from "antd"
import { useForm } from "antd/es/form/Form"
import { Content } from "antd/lib/layout/layout"
import { LayoutNav } from "components/layout.component"
import i18n from "i18n"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { $get, $put } from "services"
import { toFormatNumber as $f, verify } from 'utils/common'
import { SiteCodeAndName } from "components/site.component";

// 交收報表: 設定

const PageMain = () => {
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [platformId, setPlatFormId] = useState<null | number | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { platformId: _platformId } = location.state || {};

  useEffect(() => {
    if (_platformId) {
      setPlatFormId(_platformId);
      form.setFieldValue('platform', _platformId)
    }
  }, []);

  const { data, isValidating, mutate } = $get({
    url: `admin/provider-receivable/sites/${platformId}/settings`,
    allow: !!platformId,
  });
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    if (data?.Data) {
      resetList();
    };
  }, [data]);

  const resetList = () => {
    if (data) {
      const ary: any = [];
      const apiList = JSON.parse(JSON.stringify(data.Data));
      apiList.forEach((record: any) => {
        record.Detail.forEach((item: any, i: number) => {
          item.rowSpan = i === 0 ? record.Detail.length : 0;
          ary.push(item);
          form.setFieldValue(`rate-${item.Id}`, $f(item.Rate * 100, 2));
        });
      });
      setList(ary);
    }
  }

  const onFinish = (formData: any) => {
    const send = list.map((item: any) => ({
      ProviderId: item.ProviderId,
      ProviderCode: item.ProviderCode,
      CategoryId: item.CategoryId,
      CategoryCode: item.CategoryCode,
      Rate: parseFloat(formData[`rate-${item.Id}`]) / 100,
    }));
    $put({
      url: `admin/provider-receivable/sites/${platformId}/settings`,
      send,
      success: () => {
        setIsEdit(false);
        message.success(i18n.t('updateSuccess'));
        mutate();
      }
    }, setIsLoading);
  };

  const onCancel = () => {
    setIsEdit(false);
    resetList();
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="space-between" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="platform">
                    <SiteCodeAndName name="platform" form={form} apiUrl={'admin/provider-receivable/sites' } setSiteId={setPlatFormId} disabled={isEdit} className="w-12"/>
                  </Form.Item>
                </Col>
                {
                  isEdit
                    ? <Row gutter={[12, 12]}>
                      <Col><Button onClick={onCancel}>{i18n.t('cancel')}</Button></Col>
                      <Col>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                          {i18n.t('confirm')}
                        </Button>
                      </Col>
                    </Row>
                    : <Col><Button type="primary" onClick={() => setIsEdit(true)}
                      disabled={!form.getFieldValue('platform')}>{i18n.t('edit')}</Button></Col>
                }
              </Row>
            </Col>
            <Col span={24}>
              <Table
                loading={isValidating || isLoading}
                size="middle"
                bordered
                dataSource={list.map((item: any) => ({ key: item.Id, ...item }))}
                columns={[
                  {
                    dataIndex: 'ProviderCode',
                    title: i18n.t('gameProvider'),
                    width: '30%',
                    onCell: (record: any) => ({ rowSpan: record.rowSpan }),
                  },
                  {
                    dataIndex: 'CategoryCode',
                    title: i18n.t('gameCategory'),
                    width: '35%',
                    render: (val) => i18n.t(val)
                  },
                  {
                    dataIndex: 'Rate',
                    title: `${i18n.t('rate')}%`,
                    width: '35%',
                    render: (val, record, i) => <>
                      <Form.Item name={`rate-${record.Id}`} rules={verify({ max: 100 })}>
                        <Input placeholder={'0.00'} disabled={!isEdit} />
                      </Form.Item>
                    </>
                  },
                ]}
                pagination={false}
              />
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  )
}

export default PageMain